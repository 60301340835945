/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import * as Types from "../../../constants/Config";
import {
  Button,
  Pagination,
  PaginationItem,
  PaginationLink,
  Spinner,
} from "reactstrap";

const axios = require("axios");

export default function index({ setUser, setSelectType }) {
  const [data, setData] = useState([]);
  const [arraySelect, setArraySelect] = useState([]);
  const [page] = useState([1, 2]);
  const [changePage, setChangePage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkedAll, setCheckedAll] = useState(false);
  const [checkTotal, setCheckTotal] = useState(false);
  const [checkManual, setCheckManual] = useState(false);
  const [newUser, setNewUser] = useState({
    apiKey: '',
    privateKey: '',
    note: '',
  });

  const handleCheckbox = (item) => {
    let array = data;
    const test = array.findIndex((a) => a.id === item.id);
    array[test].checked = !array[test].checked;
    setData(array);
    // setLoading(!loading);
    const new_arr = array.filter((item) => item.checked === true);
    // console.log(new_arr)
    setUser(new_arr);
    setArraySelect(new_arr);
    setChecked(item.checked);
    if (item.checked === false) {
      setCheckedAll(false);
    } else {
      setCheckManual(true);
    }
  };

  const handleCheckboxAllTrue = (e) => {
    setCheckedAll(!checkedAll);
    data.map((item, index) => {
      item.checked = true;
    });
  };
  const handleCheckboxAllFalse = (e) => {
    setCheckedAll(!checkedAll);
    data.map((item, index) => {
      item.checked = false;
    });
  };

  const handleChangeRadio = (e) => {
    const value = e.target.value;
    if (value === "무작위") {
      handleCheckboxAllTrue(e);
      setCheckedAll(true);
      setCheckManual(false);
      setCheckTotal(false);
      setSelectType("randomAll");
      setUser(data);
    } else if (value === "수동") {
      handleCheckboxAllFalse(e);
      setCheckedAll(false);
      setCheckTotal(false);
      setCheckManual(true);
      setSelectType("");
    } else if (value === "무작위+수동") {
      // handleCheckboxAllFalse(e);
      setCheckTotal(true);
      setCheckedAll(false);
      setCheckManual(false);
      setSelectType("");
    }
  };

  const handleChangePage = (id) => {
    setChangePage(id);
    setLoading(true);
    axios({
      method: "GET",
      url: `${Types.API_URL}api/v2/users/?from_id=${id}&limited=10`,
    })
      .then((res) => {
        if (res && res.status === 200) {
          setData(res.data);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  const RandomAll = () => {
    setLoading(true);
    if (checkedAll === false && checked === false) {
      alert("Choose first, please!!!!");
    }
    if (checkedAll === true) {
      setUser(data);
      setLoading(false);
    } else if (checkedAll === false) {
      if (checked === true) {
        setUser(arraySelect);
        setLoading(false);
      }
    }
  };

  const handleNext = (e) => {
    setChangePage(changePage + 1);
  };

  const handlePre = (e) => {
    if (changePage > 1) setChangePage(changePage - 1);
  };

  useEffect(() => {
    let last_element = page[page.length - 1];
    if (changePage === last_element) {
      page.push(last_element + 1);
      page.shift();
    }
    if (changePage > 1) {
      if (changePage === page[0]) {
        page.unshift(changePage - 1);
        page.pop();
      }
    }
    axios({
      method: "GET",
      url: `${Types.API_URL}api/v2/users/?from_id=${changePage}&limited=10`,
    })
      .then((res) => {
        if (res && res.status === 200) {
          setData(res.data);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  }, [changePage]);

  useEffect(() => {
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].checked === true) {
          setChecked(true);
        }
      }
    }
  });

  useEffect(() => {
    _getListUser();
  }, []);

  const _getListUser = async () => {
    setLoading(true);
    axios({
      method: "GET",
      url: `${Types.API_URL}api/v2/users/?from_id=1&limited=10`,
    })
      .then((res) => {
        if (res && res.status === 200) {
          setData(res.data);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  }

  const _handleDelete = (id) => {
    const isConfirm = window.confirm('당신은 확실히 삭제하고 싶습니다?');
    if (isConfirm) {
      axios({
        method: "DELETE",
        url: `${Types.API_URL}api/v2/users/${id}`,
      })
        .then((res) => {
          if (res && res.status === 200) {
            _getListUser();
          }
        })
        .catch((err) => console.log(err));
    }
  }

  const handleChangeInput = (event, key) => {
    event.persist();
    setNewUser({
      ...newUser,
      [`${key}`]: event.target.value,
    });
  }

  const addUser = () => {
    if (!newUser.apiKey || !newUser.privateKey || !newUser.note) {
      alert('충분한 입력란을 입력해야 합니다.');
      return;
    }
    setLoadingAdd(true);
    axios({
      method: "POST",
      url: `${Types.API_URL}api/v2/users/`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data: newUser,
    })
      .then((res) => {
        if (res && res.status === 200) {
          _getListUser();
          setNewUser({
            apiKey: '',
            privateKey: '',
            note: '',
          });
        }
      })
      .catch((err) => console.log(err));
    setLoadingAdd(false);
  }

  return (
    <div className="account-setting">
      <div className="container">
        <div className="account-setting__title">
          <p>계정관리</p>
        </div>
        <div className="account-setting__table">
          {loadingAdd && (
            <Spinner className="spinner-table" type="grow" color="info" />
          )}
          <table className="table ">
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>Note</th>
                <th style={{ textAlign: "center" }}>Api Key</th>
                <th style={{ textAlign: "center" }}>Private Key</th>
                <th style={{ textAlign: "center" }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th style={{ textAlign: "center" }}>
                  <input
                    style={{ textAlign: "left" }}
                    type="text"
                    className="controlling-price__width-input controlling-price__first-input"
                    name="note"
                    value={newUser.note}
                    onChange={(event) => handleChangeInput(event, 'note')}
                  />
                </th>
                <th style={{ textAlign: "center" }}>
                  <input
                    style={{ textAlign: "left" }}
                    type="text"
                    className="controlling-price__width-input controlling-price__first-input"
                    name="apiKey"
                    value={newUser.apiKey}
                    onChange={(event) => handleChangeInput(event, 'apiKey')}
                  />
                </th>
                <th style={{ textAlign: "center" }}>
                  <input
                    style={{ textAlign: "left" }}
                    type="text"
                    className="controlling-price__width-input controlling-price__first-input"
                    name="privateKey"
                    value={newUser.privateKey}
                    onChange={(event) => handleChangeInput(event, 'privateKey')}
                  />
                </th>
                <th style={{ textAlign: "center" }}>
                  <Button
                    color="success"
                    onClick={addUser}
                    disabled={false}
                  >
                    추가하다
                  </Button>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="account-setting__table">
          {loading && (
            <Spinner className="spinner-table" type="grow" color="info" />
          )}
          <table className="table ">
            <thead>
              <tr>
                <th className="table-checkbox">
                  <input
                    type="checkbox"
                    key={index}
                    checked={checkedAll}
                    onChange={() => {
                      setUser(data);
                      checkedAll === false
                        ? handleCheckboxAllTrue()
                        : handleCheckboxAllFalse();
                    }}
                  ></input>
                </th>
                <th style={{ textAlign: "center" }}>Note</th>
                <th style={{ textAlign: "center" }}>Api_key</th>
                <th style={{ textAlign: "center" }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 &&
                data.map((item, index) => (
                  <tr>
                    <th className="table-checkbox" key={index} id={item.id}>
                      <input
                        key={item.id}
                        type="checkbox"
                        checked={item.checked}
                        onChange={(e) => handleCheckbox(item)}
                      ></input>
                    </th>
                    <th style={{ textAlign: "center" }}>{item.note}</th>
                    <th style={{ textAlign: "center" }}>{item.api_key}</th>
                    <th style={{ textAlign: "center" }}>
                      <Button
                        color="danger"
                        onClick={() => _handleDelete(item.id)}
                        disabled={false}
                      >
                        삭제
                      </Button>
                    </th>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <div style={{ display: "flex", float: "right" }}>
          {data.length > 10 && (
            <Pagination aria-label="Page navigation example">
              <PaginationItem>
                <PaginationLink previous onClick={(e) => handlePre(e)} />
              </PaginationItem>
              {page.map((item, index) => {
                return (
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => handleChangePage(item)}
                      style={item === changePage ? { color: "red" } : {}}
                    >
                      {item}
                    </PaginationLink>
                  </PaginationItem>
                );
              })}
              <PaginationItem>
                <PaginationLink next onClick={(e) => handleNext(e)} />
              </PaginationItem>
            </Pagination>
          )}
        </div>
        <div className="account-setting__model">
          <form>
            <span>모드 설정 :</span>
            <input
              type="radio"
              id="all"
              name="모드설정"
              checked={checkedAll}
              value="무작위"
              onChange={handleChangeRadio}
            />
            <label htmlFor="all">무작위</label>
            <input
              type="radio"
              id="수동"
              name="모드설정"
              checked={checkManual}
              value="수동"
              onChange={handleChangeRadio}
            />
            <label htmlFor="수동">수동</label>
            <input
              type="radio"
              id="무작위+수동"
              name="모드설정"
              checked={checkTotal}
              value="무작위+수동"
              onChange={handleChangeRadio}
            />
            <label htmlFor="무작위+수동">무작위+수동</label>
          </form>
        </div>
        {/* <div className="account-setting__button">
          <Button color="primary" onClick={RandomAll}>
            실행
          </Button>
        </div> */}
        {/* <div className="account-setting__result">
          <p>
            Result :
            {result ? (
              <span>
                Id: {result.id} -- Address: {result.address}
              </span>
            ) : (
              <span>Random first!!!</span>
            )}
          </p>
        </div> */}
      </div>
    </div>
  );
}
