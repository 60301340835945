import React, {useEffect, useRef, useState} from "react";
import {Button} from "reactstrap";
import * as Types from "../../../constants/Config";
import {GetCoinRate} from "../../../services/GetCoinRate";
import {API_URL} from "../../../constants/Config";
import { formatFloat } from "../../../util/formatFloat";

const axios = require("axios");

function Home({user, selectType}) {
  const refTable = useRef();

  const [forMin, setForMin] = useState(1);
  const [forMax, setForMax] = useState(localStorage.getItem("objInput") ? JSON.parse(localStorage.getItem("objInput")).forMax : 0);
  const [waitMin, setWaitMin] = useState(localStorage.getItem("objInput") ? JSON.parse(localStorage.getItem("objInput")).waitMin : 0);
  const [waitMax, setWaitMax] = useState(localStorage.getItem("objInput") ? JSON.parse(localStorage.getItem("objInput")).waitMax : 0);
  const [priceMin, setPriceMin] = useState(localStorage.getItem("objInput") ? JSON.parse(localStorage.getItem("objInput")).priceMin : 0);
  const [priceMax, setPriceMax] = useState(localStorage.getItem("objInput") ? JSON.parse(localStorage.getItem("objInput")).priceMax : 0);
  const [amountMin, setAmountMin] = useState(localStorage.getItem("objInput") ? JSON.parse(localStorage.getItem("objInput")).amountMin : 10);
  const [decimal, setDecimal] = useState(localStorage.getItem("objInput") && JSON.parse(localStorage.getItem("objInput")).decimal ? JSON.parse(localStorage.getItem("objInput")).decimal : 3);
  const [amountMax, setAmountMax] = useState(localStorage.getItem("objInput") ? JSON.parse(localStorage.getItem("objInput")).amountMax : 100);
  const [disable, setDisable] = useState(false);
  const [result, setResult] = useState([]);
  const [type, setType] = useState("무작위");
  const [intervalName, setIntervalName] = useState(null);
  const [status, setStatus] = useState(false);
  const [coinRateType, setCoinRateType] = useState("btcusdt");

  useEffect(async () => {
    await _getData();
    // const timeInterval = setInterval(async () => {
    //   await _getData();
    // }, 60000)

    // return () => {
    //   clearInterval(timeInterval);
    // }
  }, []);

  const _getData = async () => {
    let coinRates = await onGetCoinRate();

    if (!localStorage.getItem('coinRate')) {
      if (!coinRates) {
        localStorage.setItem("coinRateData", "0");
        localStorage.setItem("coinRate", coinRateType);
      } else {
        localStorage.setItem("coinRateData", coinRates[coinRateType]);
        localStorage.setItem("coinRate", coinRateType);
      }
    } else {
      setCoinRateType(localStorage.getItem('coinRate'));
      if (!coinRates) {
        localStorage.setItem("coinRateData", "0");
        localStorage.setItem("coinRate", localStorage.getItem('coinRate'));
      } else {
        localStorage.setItem("coinRateData", coinRates[localStorage.getItem('coinRate')]);
        localStorage.setItem("coinRate", localStorage.getItem('coinRate'));
      }
    }
  }

  const onGetCoinRate = async () => {
    const res = await GetCoinRate();
    if (res && res.status === 200) {
      return res.data.data;
    }
  };

  const getCurrentPrice = async () => {
    const current_price = await axios({
      method : "GET",
      url    : `${API_URL}api/v2/exchange-rate`
    });

    return current_price.data.data;
  };

  const [messengerError, setMessengerError] = useState({
    status : false,
    mess   : ""
  });

  const validateInput = () => {
    if (
      // formatFloat(forMin) === 0 ||
      formatFloat(forMax) === 0 ||
      formatFloat(waitMin) === 0 ||
      formatFloat(waitMax) === 0 ||
      formatFloat(priceMin) === 0 ||
      formatFloat(priceMax) === 0 ||
      formatFloat(amountMin) === 0 ||
      formatFloat(amountMax) === 0
    ) {
      setMessengerError({
        status : true,
        mess   : "Please fill data in input"
      });
      return false;
    }

    // if (formatFloat(forMin) === formatFloat(forMax)) {
    //   setMessengerError({
    //     status : true,
    //     mess   : "The random value of the used scope cannot be equal."
    //   });
    //   return false;
    // }

    if (formatFloat(waitMin) === formatFloat(waitMax)) {
      setMessengerError({
        status : true,
        mess   : "The random value of the time range must not be equal."
      });

      return false;
    }

    if (formatFloat(priceMin).toFixed(5) === formatFloat(priceMax).toFixed(5)) {
      setMessengerError({
        status : true,
        mess   : "Random value price range must not be equal."
      });
      return false;
    }

    if (formatFloat(amountMin) === formatFloat(amountMax)) {
      setMessengerError({
        status : true,
        mess   : "Random value Range Quantity cannot be equal."
      });
      return false;
    }

    setMessengerError({
      status : false,
      mess   : ""
    });

    return true;
  };

  const startFunc = () => {
    if (validateInput()) {
      let objInput = {
        // forMin    : forMin,
        forMax    : forMax,
        waitMin   : waitMin,
        waitMax   : waitMax,
        priceMin  : priceMin,
        priceMax  : priceMax,
        amountMin : amountMin,
        amountMax : amountMax,
        decimal: decimal,
      };
      localStorage.setItem("objInput", JSON.stringify(objInput));
      setDisable(true);
      var forCount = Math.round(parseInt(forMin) + Math.random() * (parseInt(forMax) - parseInt(forMin)));
      var waitCount = Math.round(parseInt(waitMin) + Math.random() * (parseInt(waitMax) - parseInt(waitMin)));
      var count = 0;
      var check = false;
      if (user.length > 0) {
        setIntervalName(
          setInterval(async function () {
            if (count < forCount) {
              do {
                var current_price = await getCurrentPrice();
                var price = formatFloat(
                  (
                    Math.random() *
                    (formatFloat(priceMax, parseInt(decimal)) - formatFloat(priceMin, parseInt(decimal))) +
                    formatFloat(priceMin, parseInt(decimal))
                  ).toFixed(parseInt(decimal))
                );
              } while (current_price === price);


              var amount = Math.round(
                parseInt(amountMin) +
                Math.random() * (parseInt(amountMax) - parseInt(amountMin))
              );
              if (type === "매도우선") {
                let percent = Math.round(Math.random() * 9 + 1);
                if (percent < 3) {
                  const test = await buy(price, amount);
                  if (test.data.data !== null) {
                    let noteClone = test.config.data
                      .split(",")[3]
                      .split(":")[1]
                      .replaceAll(`\\"`, ``)
                      .replaceAll(`"`, ``);
                    let dataClone = {
                      DateTime : new Date().toLocaleString(),
                      OrderId  : test.data.data.order_id,
                      Catagory : "BUY",
                      Amount   : amount,
                      Price    : price,
                      Note     : noteClone
                    };
                    result.push(dataClone);
                    setResult([...result]);
                    count++;
                  }
                } else {
                  const test = await sell(price, amount);
                  if (test.data.data !== null) {
                    let noteClone = test.config.data
                      .split(",")[3]
                      .split(":")[1]
                      .replaceAll(`\\"`, ``)
                      .replaceAll(`"`, ``);
                    let dataClone = {
                      DateTime : new Date().toLocaleString(),
                      OrderId  : test.data.data.order_id,
                      Catagory : "SELL",
                      Amount   : amount,
                      Price    : price,
                      Note     : noteClone
                    };
                    result.push(dataClone);
                    setResult([...result]);
                    count++;
                  }
                }
              } else if (type === "매수우선") {
                let percent = Math.round(Math.random() * 9 + 1);

                if (percent < 3) {
                  const test = await sell(price, amount);
                  if (test.data.data !== null) {
                    let noteClone = test.config.data
                      .split(",")[3]
                      .split(":")[1]
                      .replaceAll(`\\"`, ``)
                      .replaceAll(`"`, ``);
                    let dataClone = {
                      DateTime : new Date().toLocaleString(),
                      OrderId  : test.data.data.order_id,
                      Catagory : "SELL",
                      Amount   : amount,
                      Price    : price,
                      Note     : noteClone
                    };
                    result.push(dataClone);
                    setResult([...result]);
                    count++;
                  }
                } else {
                  const test = await buy(price, amount);
                  if (test.data.data !== null) {
                    let noteClone = test.config.data
                      .split(",")[3]
                      .split(":")[1]
                      .replaceAll(`\\"`, ``)
                      .replaceAll(`"`, ``);
                    let dataClone = {
                      DateTime : new Date().toLocaleString(),
                      OrderId  : test.data.data.order_id,
                      Catagory : "BUY",
                      Amount   : amount,
                      Price    : price,
                      Note     : noteClone
                    };
                    result.push(dataClone);
                    setResult([...result]);
                    count++;
                  }
                }
              } else if (type === "coinRate") {
                let coinRates = await onGetCoinRate();
                let currentPrice = formatFloat(coinRates[coinRateType]);
                let previousPrice = formatFloat(localStorage.getItem("coinRateData"));

                if (previousPrice <= currentPrice) {
                  const test = await buy(price, amount);
                  if (test.data.data !== null) {
                    let noteClone = test.config.data
                      .split(",")[3]
                      .split(":")[1]
                      .replaceAll(`\\"`, ``)
                      .replaceAll(`"`, ``);
                    let dataClone = {
                      DateTime : new Date().toLocaleString(),
                      OrderId  : test.data.data.order_id,
                      Catagory : "BUY",
                      Amount   : amount,
                      Price    : price,
                      Note     : noteClone
                    };
                    result.push(dataClone);
                    setResult([...result]);
                    count++;
                  }
                } else {
                  const test = await sell(price, amount);
                  if (test.data.data !== null) {
                    let noteClone = test.config.data
                      .split(",")[3]
                      .split(":")[1]
                      .replaceAll(`\\"`, ``)
                      .replaceAll(`"`, ``);
                    let dataClone = {
                      DateTime : new Date().toLocaleString(),
                      OrderId  : test.data.data.order_id,
                      Catagory : "SELL",
                      Amount   : amount,
                      Price    : price,
                      Note     : noteClone
                    };
                    result.push(dataClone);
                    setResult([...result]);
                    count++;
                  }
                }

                localStorage.setItem(
                  "coinRateData",
                  JSON.stringify(currentPrice)
                );
              } else {
                //랜덤기능(무작위)

                var random = Math.round(1 + Math.random() * 9);
                var randomTemp1 = 0

                if (random < 5) {
                  randomTemp1 = random;
                  const test = await sell(price, amount);

                  if (test.data.data !== null) {

                    let noteClone = test.config.data
                      .split(",")[3]
                      .split(":")[1]
                      .replaceAll(`\\"`, ``)
                      .replaceAll(`"`, ``);
                    let dataClone = {
                      DateTime : new Date().toLocaleString(),
                      OrderId  : test.data.data.order_id,
                      Catagory : "SELL",
                      Amount   : amount,
                      Price    : price,
                      Note     : noteClone
                    };
                    result.push(dataClone);
                    setResult([...result]);
                    count++;

                  }


                } else if (random > 6) {
                  const test1 = await buy(price, amount);
                  if (test1.data.data !== null) {
                    let noteClone = test1.config.data
                      .split(",")[3]
                      .split(":")[1]
                      .replaceAll(`\\"`, ``)
                      .replaceAll(`"`, ``);
                    let dataClone = {
                      DateTime : new Date().toLocaleString(),
                      OrderId  : test1.data.data.order_id,
                      Catagory : "BUY",
                      Amount   : amount,
                      Price    : price,
                      Note     : noteClone
                    };
                    result.push(dataClone);
                    setResult([...result]);
                    count++;
                  }
                }

              }
              window.scrollTo(0, 1100);
              refTable.current.scrollTo(0, refTable.current.scrollHeight);
            } else {
              if (check === false) {
                check = true;
                alert("Done");
                setDisable(false);
              }
            }
          }, waitCount * 100)
        );
      } else {
        setDisable(false);
        alert("You haven't choosen users");
      }
    }
  };

  const getUser = async () => {
    if (selectType === "randomAll") {
      const randomUser = await axios({
        method : "POST",
        url    : `${Types.API_URL}api/v2/task3`,
        data   : {
          manually : false,
          users    : []
        }
      });
      return randomUser;
    } else {
      const randomUser = await axios({
        method : "POST",
        url    : `${Types.API_URL}api/v2/task3`,
        data   : {
          manually : true,
          users    : user
        }
      });
      return randomUser;
    }
  };

  const getPriceLists = async () => {
    const getLists = await axios({
      method : "POST",
      url    : "https://btmhgl.com/api/v1/list-sell-buy"

    });
    return getLists;
  };

  const buy = async (price, amount) => {
    const getUseraaaaa = await getUser();
    const buyToken = await axios({
      method : "POST",
      url    : `${Types.API_URL}api/v2/buy`,
      data   : {
        coinBuy : price,
        amount  : amount,
        user    : getUseraaaaa.data
      }
    });
    if (buyToken && buyToken.status === 200) return buyToken;
  };

  const sell = async (price, amount) => {
    const getUseraaaaa = await getUser();
    setStatus(!status);
    const sellToken = await axios({
      method : "POST",
      url    : `${Types.API_URL}api/v2/sell`,
      data   : {
        coinSell : price,
        amount   : amount,
        user     : getUseraaaaa.data
      }
    });
    if (sellToken && sellToken.status === 200) return sellToken;
  };

  const handleChangeTypeCoinRate = async (e) => {
    setCoinRateType(e.target.value);
    let coinRates = await onGetCoinRate();

    if (!coinRates) {
      localStorage.setItem("coinRateData", "0");
    } else {
      localStorage.setItem("coinRateData", coinRates[e.target.value]);
      localStorage.setItem("coinRate", e.target.value);
    }
  };

  return (
    <div className="cross-trading">
      <div className="container">
        <p className="cross-trading__title">자전거래</p>
        <div className="cross-trading__group-input">
          <div className="group-input__1 ">
            <div className="range-of-price col-md-6 col-lg-6 ">
              <span className="price__span">가격 범위 :</span>
              <input
                className="price__input"
                type="number"
                value={priceMin}
                onChange={(e) => setPriceMin(e.target.value)}
              />
              <input
                className="price__input"
                type="number"
                value={priceMax}
                onChange={(e) => setPriceMax(e.target.value)}
              />
            </div>
            <div className="range-of-time col-md-6 col-lg-6">
              <span className="price__span">시간범위 :</span>
              <input
                className="price__input"
                type="number"
                value={waitMin}
                onChange={(e) => setWaitMin(e.target.value)}
              />
              <input
                className="price__input"
                type="number"
                value={waitMax}
                onChange={(e) => setWaitMax(e.target.value)}
              />
            </div>
          </div>
          <div className="group-input__1">
            <div className="range-of-amount col-md-6 col-lg-6">
              <span className="price__span">수량 범위 :</span>
              <input
                className="price__input"
                type="number"
                max={100}
                min={10}
                value={amountMin}
                onChange={(e) => setAmountMin(e.target.value)}
              />
              <input
                className="price__input"
                type="number"
                max={100}
                min={10}
                value={amountMax}
                onChange={(e) => setAmountMax(e.target.value)}
              />
            </div>
            <div className="range-of-use col-md-6 col-lg-6">
              <span className="price__span">사용 범위 :</span>
              {/* <input
                className="price__input"
                type="number"
                value={forMin}
                onChange={(e) => setForMin(e.target.value)}
              /> */}
              <input
                className="price__input"
                type="number"
                value={forMax}
                onChange={(e) => setForMax(e.target.value)}
              />
            </div>
          </div>
          <div className="group-input__2">
            <div className="range-of-amount col-md-6 col-lg-6">
              <span className="price__span">소수점 : </span>
              <input
                className="price__input"
                type="number"
                max={20}
                min={1}
                step={1}
                value={decimal}
                onKeyDown={e => {
                  if ([',', '.', 'e'].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                onChange={(e) => {
                  if (!parseInt(e.target.value) || parseInt(e.target.value) && parseInt(e.target.value) >= 0 && parseInt(e.target.value) <= 20) {
                    setDecimal(e.target.value);
                  }
                }}
              />
            </div>
          </div>
        </div>
        {messengerError.status && (
          <p
            style={{
              color        : "red",
              marginBottom : 20
            }}
          >
            {messengerError.mess}
          </p>
        )}
        <div className="cross-trading__group">
          <div className="cross-trading__group-radio">
            <span>모드설정 : </span>
            <input
              type="radio"
              id="무작위"
              name="모드설정"
              value="무작위"
              onChange={(e) => setType(e.target.value)}
              checked={type === "무작위" ? true : false}
            />
            <label htmlFor="무작위">무작위</label>
            <input
              type="radio"
              id="매도우선"
              name="모드설정"
              value="매도우선"
              onChange={(e) => setType(e.target.value)}
              checked={type === "매도우선" ? true : false}
            />
            <label htmlFor="매도우선">매도우선</label>
            <input
              type="radio"
              id="매수우선"
              name="모드설정"
              value="매수우선"
              onChange={(e) => setType(e.target.value)}
              checked={type === "매수우선" ? true : false}
            />
            <label htmlFor="매수우선">매수우선</label>
            <input
              type="radio"
              id="coinRate"
              name="모드설정"
              value="coinRate"
              onChange={(e) => setType(e.target.value)}
              checked={type === "coinRate" ? true : false}
            />
          </div>
          <div className="col-4 col-md-3 select-price">
            <div className="form-group">
              <select
                id="exampleFormControlSelect1"
                className="form-control controlling-price__select-width"
                value={coinRateType}
                onChange={handleChangeTypeCoinRate}
              >
                <option value="btcusdt">BTC</option>
                <option value="ethusdt">ETH</option>
                <option value="eosusdt">EOS</option>
              </select>
            </div>
          </div>
        </div>
        <div className="cross-trading__btn">
          <Button
            color="primary"
            disabled={disable}
            onClick={() => startFunc()}
          >
            실행
          </Button>
          <Button
            color="success"
            onClick={() => {
              clearInterval(intervalName);
              alert("Done");
              setDisable(false);
            }}
          >
            중지
          </Button>
        </div>
      </div>
      <div
        ref={refTable}
        className="container table-wrap-1"
        style={{
          marginTop : "20px",
          maxHeight : result.length < 1 ? 0 : "380px"
        }}
      >
        {/* <div className="table-header">
          <ul className="header-list">
            <li className="item-1"></li>
            <li className="item-2">Date Time</li>
            <li className="item-3">Order Id</li>
            <li className="item-3">Catagory</li>
            <li className="item-4">Amount</li>
            <li className="item-4">Price</li>
          </ul>
        </div> */}
        {/* <div
          className="table-wrap"
          style={{
            height: result.length < 1 ? 0 : "380px",
          }}
        > */}
        <table className="table table-history">
          <thead>
          <tr>
            <th className="item-1-1" style={{width : "2%"}} scope="col">
              #
            </th>
            <th className="item-2-1" scope="col">
              Note
            </th>
            <th className="item-3-1" scope="col">
              Date Time
            </th>
            <th className="item-4-1" scope="col">
              Order Id
            </th>
            <th className="item-5-1" scope="col">
              Catagory
            </th>
            <th className="item-6-1" scope="col">
              Amount
            </th>
            <th className="item-7-1" scope="col">
              Price
            </th>
          </tr>
          </thead>
          <tbody>
          {result &&
            result.map((item, index) => {
              return (
                <tr>
                  <th
                    scope="row"
                    className="d-flex align-items-center"
                    style={{width : "10%", height : "100%"}}
                  >
                    {index + 1}
                  </th>
                  <td>{item.Note}</td>
                  <td>{item.DateTime}</td>
                  <td>{item.OrderId}</td>
                  <td
                    style={
                      item.Catagory === "SELL"
                        ? {
                          color : "red"
                        }
                        : {
                          color : "green"
                        }
                    }
                  >
                    {item.Catagory}
                  </td>
                  <td>{item.Amount}</td>
                  <td>{item.Price}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* </div> */}
      </div>
    </div>
  );
}

export default Home;
