import request from "../util/Api";
import * as Urls from "../constants/Config";

const createPriceControl = (data) => {
  return request({
    url: `${Urls.API_URL}api/v2/task2`,
    data,
    method: "POST",
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

const cancelOrderAll = (data) => {
  return request({
    url: `${Urls.API_URL}api/v2/cancel-order-all`,
    data,
    method: "POST",
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};


const pausePriceControl = (data) => {
  return request({
    url: `${Urls.API_URL}api/v2/task2x`,
    data,
    method: "POST",
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};

export { createPriceControl, cancelOrderAll, pausePriceControl };