import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Home from "./page/Home";
import AccountSetting from "./page/AccountSetting";
import ControllingPrice from "./page/ControllingPrice";
import Login from "./page/Login";
function HomePage() {
  const [user, setUser] = useState([]);
  const [selectType, setSelectType] = useState("");
  const [status, setStatus] = useState("");
  const auth = JSON.parse(sessionStorage.getItem("loggedIn"))
    ? JSON.parse(sessionStorage.getItem("loggedIn"))
    : false;
  return (
    <Router>
      {/* <ScrollToTop /> */}
      <Switch>
        <Route path="/home">
          {auth ? (
            <>
              <AccountSetting setUser={setUser} setSelectType={setSelectType} />
              <Home user={user} selectType={selectType} />
              <ControllingPrice user={user} selectType={selectType} />
            </>
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: "/home" },
              }}
            />
          )}
        </Route>
        <Route path="/login">
          {auth ? (
            <Redirect
              to={{
                pathname: "/home",
                state: { from: "/login" },
              }}
            />
          ) : (
            <Login setStatus={setStatus} status={status} />
          )}
        </Route>
        <Route path="/">
          {auth ? (
            <Redirect
              to={{
                pathname: "/home",
                state: { from: "/" },
              }}
            />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: "/" },
              }}
            />
          )}
        </Route>
      </Switch>
    </Router>
  );
}

export default HomePage;
