import React, { useState } from "react";

function Login({ setStatus, status }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [validate, setValidate] = useState(false);
  const handleSignIn = () => {
    if (username === "admin" && password === "Seoul#12345") {
      sessionStorage.setItem("loggedIn", true);
      setStatus(!status);
    } else {
      setValidate(true);
    }
  };
  return (
    <div className="login__page">
      <div className="wrapper">
        <h1>Sign In</h1>
        <div className="row">
          <div className="col-8">
            <label htmlFor="username">Username</label>
            <input
              id="username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            {validate && username !== "admin" && (
              <span style={{ color: "red", marginTop: 10, display: "block" }}>
                Wrong Username
              </span>
            )}
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {validate && password !== "Seoul#12345" && (
              <span style={{ color: "red", marginTop: 10, display: "block" }}>
                Wrong Password
              </span>
            )}
          </div>
          <div className="col-4 d-flex align-items-end">
            <div
              className="btn btn-primary btn-login"
              onClick={() => handleSignIn()}
            >
              Login
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
