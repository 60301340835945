import request from "../util/Api";
import {API_URL} from "../constants/Config";

const GetCoinRate = () => {
  return request({
    url    : `${API_URL}api/v2/market`,
    method : "GET"
  }).then((res) => {
    return res;
  }).catch((error) => {
    return error.response;
  });
};

export {GetCoinRate};
