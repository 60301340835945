import React, { useRef, useState } from "react";
import { Button } from "reactstrap";
import {
  createPriceControl,
  cancelOrderAll,
  pausePriceControl,
} from "../../../services/PriceControl";
import * as Types from "../../../constants/Config";
import { formatFloat } from "../../../util/formatFloat";
const axios = require("axios");
const ControllingPrice = ({ user, selectType }) => {
  const refTable = useRef();
  const [priceControl, setPriceControl] = useState(
    localStorage.getItem("localPriceControl")
      ? JSON.parse(localStorage.getItem("localPriceControl"))
      : {
        price_sell: 0,
        total_sell: 0,
        price_buy: 0,
        total_buy: 0,
        targetPrice: 0,
        checkboxSell: true,
        checkboxBuy: true,
        checkboxTargetPrice: false,
      }
  );

  const [isStartCallApi, setIsStartCallApi] = useState(false);

  const [intervalPriceControl, setIntervalPriceControl] = useState();
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const [priceControlHistory, setPriceControlHistory] = useState([]);

  const handleChangeInput = (event) => {
    event.persist();
    setIsDisabledButton(false);
    clearInterval(intervalPriceControl);

    switch (event.target.name) {
      case "checkboxSell":
        setPriceControl({
          ...priceControl,
          checkboxSell: event.target.value,
          checkboxTargetPrice: false,
        });
        break;
      case "checkboxBuy":
        setPriceControl({
          ...priceControl,
          checkboxBuy: event.target.value,
          checkboxTargetPrice: false,
        });
        break;
      case "checkboxTargetPrice":
        setPriceControl({
          ...priceControl,
          checkboxSell: false,
          checkboxBuy: false,
          checkboxTargetPrice: event.target.value,
        });
        break;
      default:
        break;
    }

    setPriceControl((priceControl) => ({
      ...priceControl,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    }));
  };

  const onCheckValidate = () => {
    let isValidate = true;
    if (
      priceControl.checkboxSell &&
      (formatFloat(priceControl.price_sell) === 0 ||
        formatFloat(priceControl.total_sell) === 0)
    ) {
      isValidate = false;
    }

    if (
      priceControl.checkboxBuy &&
      (formatFloat(priceControl.price_buy) === 0 ||
        formatFloat(priceControl.total_buy) === 0)
    ) {
      isValidate = false;
    }

    if (
      priceControl.checkboxTargetPrice &&
      formatFloat(priceControl.targetPrice) === 0
    ) {
      isValidate = false;
    }

    return isValidate;
  };

  const onStartPriceControl = () => {
    const checkValidate = onCheckValidate();

    if (checkValidate) {
      let localPriceControl = {
        price_sell: priceControl.price_sell,
        total_sell: priceControl.total_sell,
        price_buy: priceControl.price_buy,
        total_buy: priceControl.total_buy,
        checkboxSell: priceControl.checkboxSell,
        checkboxBuy: priceControl.checkboxBuy,
        targetPrice: priceControl.targetPrice,
        checkboxTargetPrice: priceControl.checkboxTargetPrice,
      };
      localStorage.setItem(
        "localPriceControl",
        JSON.stringify(localPriceControl)
      );

      console.log("user: ", user);
      if (user.length > 0) {
        setIsStartCallApi(true);
        setIsDisabledButton(true);
        onCreatePriceControl();
        setIntervalPriceControl(
          setInterval(() => {
            onCreatePriceControl();
          }, 30000)
        );
      } else {
        setIsDisabledButton(false);
        alert("You haven't choosen users");
      }
    } else {
      alert("Please enter a number in the input other than 0");
    }
  };

  const getUserApi = async () => {
    if (selectType === "randomAll") {
      const randomUser = await axios({
        method: "POST",
        url: `${Types.API_URL}api/v2/task3`,
        data: {
          manually: false,
          users: [],
        },
      });
      return randomUser;
    } else {
      const randomUser = await axios({
        method: "POST",
        url: `${Types.API_URL}api/v2/task3`,
        data: {
          manually: true,
          users: user,
        },
      });
      return randomUser;
    }
  };

  const onCreatePriceControl = async () => {
    const getUser = await getUserApi();
    localStorage.setItem("transactionUser", JSON.stringify(getUser.data));

    const data = {
      price_sell: priceControl.checkboxTargetPrice
        ? formatFloat(priceControl.targetPrice)
        : formatFloat(priceControl.price_sell),
      total_sell: priceControl.checkboxTargetPrice
        ? 10
        : formatFloat(priceControl.total_sell),
      price_buy: priceControl.checkboxTargetPrice
        ? formatFloat(priceControl.targetPrice)
        : formatFloat(priceControl.price_buy),
      total_buy: priceControl.checkboxTargetPrice
        ? 10
        : formatFloat(priceControl.total_buy),
      is_sell: priceControl.checkboxTargetPrice
        ? true
        : priceControl.checkboxSell,
      is_buy: priceControl.checkboxTargetPrice
        ? true
        : priceControl.checkboxBuy,
      user: getUser.data,
    };

    const res = await createPriceControl(data);
    if (res.status === 200 && res.data.side !== "NONE") {
      var obj = {};
      res.data.forEach(item => {
        obj = {
          DateTime: new Date().toLocaleString(),
          Note: item.msg === "suc" ? getUser.data.note : item.msg,
          OrderId: item.msg === "suc" ? item.data.order_id : '',
          Catagory: item.side,
          Price: item.side === "SELL" ? data.price_sell : data.price_buy,
          PriceCurrent: item.current_price,
        };
        priceControlHistory.push(obj);
      });

      setPriceControlHistory([...priceControlHistory]);
      window.scrollTo(0, document.body.scrollHeight);
      refTable.current.scrollTo(0, refTable.current.scrollHeight);
    }
  };

  const onCancelOrderAll = async (user) => {
    await cancelOrderAll({
      user,
    });
  };

  const onPausePriceControl = () => {
    user &&
      user.map((item, index) => {
        onCancelOrderAll(item);
      });
    // onCallApiPausePriceControl();
    clearInterval(intervalPriceControl);
  };

  const onCallApiPausePriceControl = async () => {
    const data = {
      price_sell: formatFloat(priceControl.price_sell),
      total_sell: formatFloat(priceControl.total_sell) * 0.1,
      price_buy: formatFloat(priceControl.price_buy),
      total_buy: formatFloat(priceControl.total_buy) * 0.1,
      is_sell: priceControl.checkboxSell,
      is_buy: priceControl.checkboxBuy,
      user: JSON.parse(localStorage.getItem("transactionUser")),
    };

    const res = await pausePriceControl(data);
    if (res.status === 200 && res.data.side !== "NONE") {
      var obj = {
        DateTime: new Date().toLocaleString(),
        Note:
          res.data.msg === "suc"
            ? JSON.parse(localStorage.getItem("transactionUser")) &&
            JSON.parse(localStorage.getItem("transactionUser")).note
            : res.data.msg,
        OrderId: res.data.msg === "suc" ? res.data.data.order_id : 0,
        Catagory: res.data.side,
        Price: res.data.side === "SELL" ? data.price_sell : data.price_buy,
        PriceCurrent: res.data.current_price,
      };
      priceControlHistory.unshift(obj);
      setPriceControlHistory([...priceControlHistory]);
    }
  };

  return (
    <div className="container mt-5">
      <div className="row">
        <h2 className="controlling-price__title">가격 제어</h2>
      </div>
      <div className="row">
        <div className="offset-md-5 col-md-3 offset-lg-4 col-lg-2">
          <span className="text-center controlling-price__hidden-sm">가격</span>
        </div>
        <div className="col-md-3 offset-lg-1 col-lg-3">
          <span className=" text-center  controlling-price__hidden-sm">
            리필수량
          </span>
        </div>
      </div>
      <div className="row mt-4 align-items-center">
        <div className="col-md-4 col-lg-3 col-xl-2">
          <div className="controlling-price__checkbox-f">
            <span style={{ whiteSpace: "nowrap" }}>
              상한 가격 보호 : &nbsp;&nbsp;
            </span>
            <input
              style={{ textAlign: "right" }}
              type="checkbox"
              id="checkboxSell"
              name="checkboxSell"
              checked={priceControl.checkboxSell}
              onChange={(event) => handleChangeInput(event)}
              disabled={isStartCallApi}
            />{" "}
            &nbsp;
            <label htmlFor="checkboxSell">On/Off</label>
          </div>
        </div>
        <div className="col-md-4">
          <div>
            <span className="controlling-price__show-sm">가격</span>
            <input
              style={{ textAlign: "right" }}
              type="number"
              className="controlling-price__width-input controlling-price__second-input"
              name="price_sell"
              value={priceControl.price_sell}
              onChange={(event) => handleChangeInput(event)}
              disabled={!priceControl.checkboxSell || isStartCallApi}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div>
            <span className="controlling-price__show-sm">리필수량</span>
            <input
              style={{ textAlign: "right" }}
              type="number"
              name="total_sell"
              value={priceControl.total_sell}
              className="controlling-price__width-input controlling-price__first-input"
              onChange={(event) => handleChangeInput(event)}
              disabled={!priceControl.checkboxSell || isStartCallApi}
            />
          </div>
        </div>
      </div>
      <div className="row mt-3 align-items-center">
        <div className="col-md-4 col-lg-3 col-xl-2">
          <div className="controlling-price__checkbox-f">
            <span style={{ whiteSpace: "nowrap" }}>
              하한 가격 보호 : &nbsp;&nbsp;
            </span>
            <input
              type="checkbox"
              style={{ textAlign: "right" }}
              id="checkboxBuy"
              name="checkboxBuy"
              checked={priceControl.checkboxBuy}
              onChange={(event) => handleChangeInput(event)}
              disabled={isStartCallApi}
            />{" "}
            &nbsp;
            <label htmlFor="checkboxBuy">On/Off</label>
          </div>
        </div>
        <div className="col-md-4">
          <span className="controlling-price__show-sm">가격</span>

          <input
            style={{ textAlign: "right" }}
            type="number"
            className="controlling-price__width-input controlling-price__second-input"
            name="price_buy"
            value={priceControl.price_buy}
            onChange={(event) => handleChangeInput(event)}
            disabled={!priceControl.checkboxBuy || isStartCallApi}
          />
        </div>
        <div className="col-md-4">
          <span className="controlling-price__show-sm">리필수량</span>
          <input
            style={{ textAlign: "right" }}
            type="number"
            className="controlling-price__width-input controlling-price__first-input"
            name="total_buy"
            value={priceControl.total_buy}
            onChange={(event) => handleChangeInput(event)}
            disabled={!priceControl.checkboxBuy || isStartCallApi}
          />
        </div>
      </div>
      <div className="row mt-4 align-items-center">
        <div className="col-md-4 col-lg-3 col-xl-2 ">
          <div className="controlling-price__checkbox-f">
            <span style={{ whiteSpace: "nowrap" }}>
              목표 가격 추적 : &nbsp;&nbsp;
            </span>
            <input
              type="checkbox"
              name="checkboxTargetPrice"
              id="checkboxTargetPrice"
              checked={priceControl.checkboxTargetPrice}
              onChange={(event) => handleChangeInput(event)}
            />
            <label htmlFor="checkboxTargetPrice" style={{ whiteSpace: "nowrap" }}>
              &nbsp;On/Off
            </label>
          </div>
        </div>
        <div className="col-md controlling-price__target-price ">
          <span className="controlling-price__title-mobile">
            목표 가격 : &nbsp;&nbsp;
          </span>
          <input
            type="number"
            className="controlling-price__first-input"
            name="targetPrice"
            value={priceControl.targetPrice}
            disabled={!priceControl.checkboxTargetPrice}
            style={{ textAlign: "right", padding: 3, outline: "none" }}
            onChange={(event) => handleChangeInput(event)}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="cross-trading__btn">
          <Button
            color="primary"
            onClick={() => {
              onStartPriceControl();
            }}
            disabled={isDisabledButton}
          >
            저장
          </Button>
          <Button
            color="success"
            onClick={() => {
              onPausePriceControl();
              setIsDisabledButton(false);
              setIsStartCallApi(false);
            }}
            disabled={!isDisabledButton}
          >
            실행
          </Button>
          {/* <Button color="info">중지</Button>
          <Button color="warning">상태보기</Button> */}
        </div>
      </div>
      <div>
        <div className="table-header">
          <ul className="header-list">
            <li className="item-1-1"></li>
            <li className="item-2-1">Note</li>
            <li className="item-3-1">Date Time</li>
            <li className="item-4-1">Order Id</li>
            <li className="item-5-1">Catagory</li>
            <li className="item-6-1">Price Sell / Buy</li>
            <li className="item-7-1">Current Price</li>
          </ul>
        </div>
        <div
          ref={refTable}
          className="table-wrap"
          style={{
            height: priceControlHistory.length < 1 ? 0 : "380px",
            marginBottom: 20,
          }}
        >
          <table className="table table-history">
            <tbody>
              {priceControlHistory &&
                priceControlHistory.map((item, index) => {
                  return (
                    <tr key={index}>
                      <th style={{ width: "7%" }} scope="row">
                        {index + 1}
                      </th>
                      <td
                        style={{
                          width: "calc(100% / 7)",
                          color: item.OrderId == 0 && "red",
                        }}
                      >
                        {item.Note}
                      </td>
                      <td style={{ width: "calc(100% / 7)" }}>
                        {item.DateTime}
                      </td>
                      <td style={{ width: "calc(100% / 7)" }}>
                        {item.OrderId}
                      </td>
                      <td
                        style={
                          item.Catagory === "SELL"
                            ? {
                              color: "red",
                              width: "calc(100% / 7)",
                            }
                            : {
                              color: "green",
                              width: "calc(100% / 7)",
                            }
                        }
                      >
                        {item.Catagory}
                      </td>
                      <td style={{ width: "calc(100% / 7)" }}>{item.Price}</td>
                      <td style={{ width: "calc(100% / 7)" }}>
                        {item.PriceCurrent}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default ControllingPrice;
